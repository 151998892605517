function focusersm(element) {
    var c = document.getElementsByClassName("servicessm");
    for (var i = 0; i < c.length; i++) {
        if(i == element)
            c[i].classList.add("focus");
        else
            c[i].classList.remove("focus");
    }
}

function focuser(element) {
    var b = document.getElementsByClassName("services");
    for (var i = 0; i < b.length; i++) {
        if (i == element)
            b[i].classList.add("focus");
        else
            b[i].classList.remove("focus");
    }
}

function focused(element) {
    var a = document.getElementsByClassName("card-img-top");            
    for (var i = 0; i < a.length; i++) {
        if(i == element)
            a[i].classList.add("focus");
        else
            a[i].classList.remove("focus");
    }
}
